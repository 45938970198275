import React, { useState, useEffect, useRef } from "react";
import "./Header.css";
import Bars from "../../../assets/bars.png";
import logo from "../../../assets/logo.png";
import { Link } from "react-scroll";
import logo1 from "../../assets/logo1.png";

const Header = () => {
  const [mobile, setMobile] = useState(window.innerWidth <= 768 ? true : false);
  const [menuOpened, setMenuOpened] = useState(false);

  // Add event listener to handle window resize
  useEffect(() => {
    const handleResize = () => {
      setMobile(window.innerWidth <= 768 ? true : false);
    };

    // Attach the event listener
    window.addEventListener("resize", handleResize);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Ref to the menu container
  const menuRef = useRef(null);

  // Add event listener to detect clicks outside the menu
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        // Clicked outside the menu, so close it
        setMenuOpened(false);
      }
    };

    // Attach the event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Remove the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="header">
      {mobile ? (
        <img src={logo1} alt="" className="logo" />
      ) : (
        <img src={logo} alt="" className="logo" />
      )}

      {menuOpened === false && mobile === true ? (
        <div
          ref={menuRef}
          style={{
            backgroundColor: "var(--green)",
            opacity: "0.4",
            padding: "0.5rem",
            borderRadius: "5px"
          }}
          onClick={() => setMenuOpened(true)}
        >
          <img
            src={Bars}
            alt=""
            style={{ width: "1.5rem", height: "1.5rem" }}
          />
        </div>
      ) : (
        <ul className="header-menu" ref={menuRef}>
          <li>
            <Link
              onClick={() => setMenuOpened(false)}
              to="Home"
              spy={true}
              smooth={true}
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              onClick={() => setMenuOpened(false)}
              to="programs"
              spy={true}
              smooth={true}
            >
              Our services
            </Link>
          </li>
          <li>
            <Link
              onClick={() => setMenuOpened(false)}
              to="ExtraService"
              spy={true}
              smooth={true}
            >
              Extra Services
            </Link>
          </li>
          {mobile && (
            <li>
              <Link
                onClick={() => setMenuOpened(false)}
                to="Contact"
                spy={true}
                smooth={true}
              >
                Contact Us
              </Link>
            </li>
          )}
        </ul>
      )}
    </div>
  );
};

export default Header;
