/* Update your ContactForm component */
import React, { useState, useRef } from "react";
import "./ContactMe.css"; // Create a CSS file for your ContactForm component

import emailjs from "@emailjs/browser";

const ContactForm = () => {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_3s2i6r9",
        "template_acah6pd",
        form.current,
        "LkX2wP2nz89QhfG44"
      )
      .then((result) => {
        console.log(result.text);
        console.log("message sent");
        e.target.reset();

        // Show the success pop-up
        setShowSuccessMessage(true);

        // Automatically hide the pop-up after 3 seconds (adjust as needed)
        setTimeout(() => {
          setShowSuccessMessage(false);
        }, 1000);
      })
      .catch((error) => {
        console.log(error.text);
      });
  };

  return (
    <div className="form" id="Contact">
      {showSuccessMessage && (
        <div className="success-popup">
          <p>Form submitted successfully!</p>
        </div>
      )}
      <div className="left-half">
        {/* Text for the left half */}
        <span className="stroke-text1">Contact us</span>
        <span>to know more</span>
        <span>
          Reach out to us to unlock your business potential and embark on a
          journey towards success.
        </span>
      </div>
      <div className="right-half">
        <div className="form-container">
          <form
            ref={form}
            onSubmit={sendEmail}
            method="POST"
            className="contact-form"
          >
            <div className="input-group">
              <input
                type="text"
                placeholder="Your name"
                name="name"
                className="custom-input"
                required
              />
            </div>
            <div className="input-group">
              <input
                type="tel"
                placeholder="Phone Number"
                name="phone"
                className="custom-input"
                required
              />
            </div>
            <div className="input-group">
              <input
                type="email"
                placeholder="Email Address"
                name="email"
                className="custom-input"
                required
              />
            </div>
            <div className="input-group">
              <textarea
                placeholder="Your message"
                name="message"
                className="custom-input"
                required
              />
            </div>
            <div className="input-group">
              <button className="custom-button" type="submit">
                Send a message
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
/* ContactForm.css */
