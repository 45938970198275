import React from 'react'
import "./Program.css"
import {programsDataTop } from '../../../data/programsData'
import { programsDataBottom} from '../../../data/programDataBottom'
const Program = () => {
  return (
    <div className="Programs" id="programs">
        {/* Header */}
        <div className="programs-header">
            <span className='stroke-text'>Explore Our Services </span>
            
            <span > to Shape Your Success</span>
        </div>
        <div className="program-categories">
    {programsDataTop.map((program) => (
        <div className="category" key={program.id}>
            <div className="image-container">
                {program.image}
            </div>
            <span>{program.heading}</span>
            <span>{program.details}</span>
        </div>
    ))}
    </div>
    <div className="program-categories">
        {programsDataBottom.map((program) => (
            <div className="category" key={program.id}>
                <div className="image-container">
                    {program.image}
                </div>
                <span>{program.heading}</span>
                <span>{program.details}</span>
            </div>
        ))}
    </div>


        </div>
  )
}

export default Program
